import { useState, useEffect } from "react";
import "./InitialOfferDetails.scss";
import moment from "moment";
import {
  McButton,
  McTabBar,
  McTab,
  McIcon,
  McLoadingIndicator,
} from "@maersk-global/mds-react-wrapper";
import { getAPI, postAPI } from "../../../services/CepApiServices";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import StandardOfferVO from "../StandardOffer/OfferNegotiationVO/StandardOfferVO";
import IndexHomePage from "../BaseCharterParty/IndexHomePage";
import VesselSupplyDetails from "../VesselSupplyDetails/VesselSupplyDetails";
import ExtensionModal from "../StandardOffer/OfferNegotiationVO/ExtensionModal";
import IssueRecapHomePage from "../../IssueRecap/IssueRecapHomePage";
import StepIndicatorFlow from "../../../components/StepIndicationFlow/StepIndicatorFlow";
import { toast } from "react-toastify";
import ChangeOfferTimeModal from "./ChangeOfferTimeModal";
import laderImage from "../../../assets/loader-image.png";
import SubmitNegotiationModal from "../StandardOffer/SubmitNegotiationModal";
import AcceptClauseWarningModal from "../OfferDetails/AcceptClauseWarningModal";
import UpdateUnfilledFieldsModal from "./UpdateUnfilledFieldsModal";
import MandatoryNotifyModal from "./MandatoryNotifyModal";
import {
  getAllTermsFilledVO,
  getMandatoryTermsFilledVO,
} from "../../../components/NegotiationFunctions/NegotiationFunc";
import messageSendIcon from "../../../assets/paper-plane.png";
import closeIcon from "../../../assets/close.png";
import { getExpiryTime } from "../../../utils/token";
import TokenExpiryPop from "../../../components/TokenExpiryPopup/TokenExpiryPopup";
import FeedBackPrompt from "../../../components/FeedBackPrompt/FeedBackPrompt";
import {
  LAST_FEEDBACK_TIMESTAMP_KEY,
  MdsConstants,
} from "../../../utils/Constants";

interface IPROPS {
  indicateId: any;
}
const InitiateOfferDetailsVO = ({ indicateId }: IPROPS) => {
  const [offerdata, setOfferData] = useState<any>([]);
  const [mianInfoData, setMainInfoData] = useState<any>();
  const [vesselDetails, setVesselDetails] = useState<any>([]);
  const [isExtensionModal, setIsExtensionModal] = useState(false);
  const [submitData, setSubmitData] = useState<any>([]);
  const [timerInitial, setTimerInitial] = useState(0);
  const [remainingTime, setRemainingTime] = useState<any>(0);
  const [tab, setTab] = useState(0);
  const [prevTab, setPrevTab] = useState<any>(null);
  const [acceptTimeSO, setAcceptTimeSO] = useState<any>(null);
  const [BCPData, setBCPData] = useState<any>([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const indicationRequestId = params.get("id");
  const [tabTermsAcceptTimeline, setTabTermsAcceptTimeline] = useState<any>();
  const [negotiationType, setNegotiationType] = useState<any>(null);
  const [isExtensionNotifyRunningRecap, setIsExtensionNotifyRunningRecap] =
    useState(false);
  const [runningRecapData, setRunningRecapData] = useState<any>([]);
  const [extensiontimeLineRR, setExtensionTimeLineRR] = useState<any>();
  const [acceptTimeRR, setAcceptTimeRR] = useState<any>(null);
  const [extensionModalOpen, setIsExtensionModalOpen] = useState(false);
  const [accessType, setAccessType] = useState<any>();
  const [isMainLoader, setIsMainLoader] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [stageType, setStageType] = useState<any>("");
  const [runningRecapStatus, setRunningRecapStatus] = useState<any>([]);
  const [isInitialRunningRecap, setIsInitialRunningRecap] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isBCPEditable, setIsBCPEditable] = useState(false);
  const [timerSecond, setTimerSecond] = useState<any>(0);
  const [isSubmitNegotiation, setIsSubmitNegotiation] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [standardOfferFilledData, setStandardOfferFilledData] = useState<any>(
    []
  );
  const [displayAcceptRejectChngeMsg, setDisplayAcceptRejectChngeMsg] =
    useState(false);
  const [acceptClauseWrngModal, setAcceptClauseWrngModal] = useState(false);
  const [timerExpiry, setTimerExpiry] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [unFilledData, setUnFilledData] = useState([]);
  const [ExtensionTimeLine, setExtensionTimeLine] = useState<any>(null);
  const [isMandatoryNotifyModal, setIsMandatoryNotifyModal] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [isCommentText, setIsCommentText] = useState("");
  const [commentData, setCommentData] = useState<any>([]);
  const [commentMainData, setCommentMainData] = useState<any>([]);
  const [isChatSendDisabled, setIsChatSendDisabled] = useState(false);
  const [disableEditBtn, setDisableEditBtn] = useState(false);
  const [voLiftSubTimer, setVoLiftSubTimer] = useState<any>();
  const [voLiftSubRemainingTime, setVoLiftSubRemainingTime] = useState<any>();
  const [cmLiftSubExtensionTime, setCmLiftSubExtensionTime] = useState<any>();
  const [willTokenExpire, setWillTokenExpire] = useState(false);
  const [tokenExpiryMessage, setTokenExpiryMessage] = useState("");
  const [cmTimeForExtension, setCmTimeForExtension] = useState<any>({
    userType: "VO",
    timeValue: "",
  });
  const [standardOfferAPILoad, setStandardOfferAPILoad] = useState(false);
  const [showFeedbackPrompt, setShowFeedbackPrompt] = useState(false);
  const [isPrefilledCondition, setIsPrefilledCondition] = useState(false);

  // const [contractExtended, setIsContractExtended] = useState(false);

  const [extendedContractIDBCP, setExtendedContractIDBCP] = useState<any>();
  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + " hr, " : "";
    var mDisplay = m > 0 ? m + " m" : "";
    return dDisplay + hDisplay + mDisplay;
  };

  const secondsToDhm = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    // var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + " hr, " : "";
    var mDisplay = m > 0 ? m + " m" : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " S") : "";
    return dDisplay + hDisplay + mDisplay;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">...</div>;
    }

    return (
      <div className="timer">
        <div className="text"></div>
        <div className="value">{secondsToDhms(remainingTime)}</div>
      </div>
    );
  };
  const timerProps = {
    isPlaying: true,
    size: 110,
    strokeWidth: 6,
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      calculateRemainingTime(voLiftSubTimer);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [voLiftSubTimer]);

  const calculateRemainingTime = (targetTime) => {
    const currentTime = moment()
      .subtract(0, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
    const duration = moment.duration(moment(targetTime).diff(currentTime));
    const seconds = duration.asSeconds();
    if (seconds < 59 && voLiftSubTimer) {
      setVoLiftSubRemainingTime("Time Expired");
    } else {
      setVoLiftSubRemainingTime(secondsToDhm(seconds));
    }
  };
  const handleTimer = (time: any) => {
    var currentdateTime = moment()
      .subtract(0, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
    var seconds = moment(time).diff(currentdateTime, "seconds");
    setTimerInitial(seconds);
    setRemainingTime(seconds);
  };
  const getStandardTermData = async () => {
    setIsMainLoader(true);
    const response = await getAPI(
      `InitialOffer/GetStandardOfferTermsTransactionDetail/${indicateId}`
    );
    if (response) {
      setSubmitData(response);
      setOfferData(response.standardTemplateMainTerms);
      // if (isInitialLoad) {
      //   setIsMainLoader(false);
      // } else {
      //   setIsMainLoader(false);
      // }
      setIsMainLoader(false);
      setStandardOfferAPILoad(true);
    }
  };
  const getVesselDetails = async () => {
    const response = await getAPI(
      `InitialOffer/GetVesselContractDetails/${indicateId}`
    );
    setVesselDetails(response);
  };
  // const BCPresponse = async () => {
  //   setIsMainLoader(true);
  //   const response = await getAPI(
  //     `Negotiation/GetBaseCharterPartyDocumentContent/` + indicateId + `/1`
  //   );
  //   if (response) {
  //     setBCPData(response);
  //     // if (isInitialLoad) {
  //     //   setIsMainLoader(false);
  //     // } else {
  //     //   setIsMainLoader(false);
  //     // }
  //     setIsMainLoader(false);
  //   }
  // };
  const BCPNewAPIResponse = async () => {
    setIsMainLoader(true);
    let response:any;
    if(isPrefilledCondition) {
      response = await getAPI(
        `Negotiation/GetBaseCharterPartyMainClauseContent/` + extendedContractIDBCP + `/1`+`/`+ false+`/`+false+`/`+true+`/`+false
      );
    } else {
      response = await getAPI(
        `Negotiation/GetBaseCharterPartyMainClauseContent/` + indicateId + `/1`+`/`+ true+`/`+false+`/`+false+`/`+false
      );
    }

    if (response) {
      setBCPData(response);
      const isExtension = response?.negotiationTimelineDto
        ?.extensionRequestTimeline
        ? true
        : false;
      // setIsExtensionNotifyBaseCharty(isExtension);
      // setExtensionTimeLineBC(
      //   response?.negotiationTimelineDto?.extensionRequestTimeline
      // );
      setIsMainLoader(false);
    }
  }
  const openExtensionModal = () => {
    if (tab === 0) {
      setNegotiationType("Standard Offer Document");
      // setTabTermsAcceptTimeline(
      //   submitData?.negotiationTimelineDetail?.termsAcceptTimeline
      // );
    } else if (tab === 1) {
      setNegotiationType("Contract Document");
      // setTabTermsAcceptTimeline(
      //   BCPData?.negotiationTimelineDto?.termsAcceptTimeline
      // );
    }
    setTabTermsAcceptTimeline(mianInfoData?.termAcceptTimeline);
    setIsExtensionModal(true);
  };
  const openLiftSubExtensionModal = () => {
    setNegotiationType("Lift Subs");
    setCmTimeForExtension({
      userType: "VO",
      timeValue: mianInfoData?.cmLiftSubRequestDate,
    });
    // setTabTermsAcceptTimeline(runningRecapStatus?.liftSubTimeline);
    setIsExtensionModal(true);
  };
  const closeExtensionModal = () => {
    setIsExtensionModal(false);
  };
  const reRenderFunc = () => {
    getStandardTermData();
  };
  const reRenderFuncBCP = () => {
    // BCPresponse();
    BCPNewAPIResponse();

  };
  const reRenderFuncRR = () => {
    getRecapStatus();
    getMainSectionData("no");
  };
  const getReRenderFunc = () => {
    if (tab === 0) {
      getStandardTermData();
      // getMainSectionData("no");
    } else if (tab === 1) {
      // BCPresponse();
      BCPNewAPIResponse();

      // getMainSectionData("no");
    } else if (tab === 3) {
      getRecapStatus();
    }
    getMainSectionData("no");
  };
   // call only on first time when extended before submission of counter
   const PrefilledBCPresponse = async (id?:any) => {
    setIsMainLoader(true);
    let response;
    if(id){
      response = await getAPI(
        `Negotiation/GetBCPPrefilledData/` + id 
      );
    } else {
      response = await getAPI(
        `Negotiation/GetBCPPrefilledData/` + extendedContractIDBCP 
      );
    }
   
    if (response) {
      setBCPData(response);
      const isExtension = response?.negotiationTimelineDto
        ?.extensionRequestTimeline
        ? true
        : false;
      setIsMainLoader(false);
    }
  };
  const getMainSectionData = async (type: any) => {
    const response = await getAPI(
      `Negotiation/GetNegotiationTimelineDetail/${indicateId}`
    );
    if (!response?.initiatedNegotiation) {
      return (window.location.href = "/AccessDenied");
    }
    setMainInfoData(response);
    setIsLocked(response?.islocked);
    setAccessType(response?.accessType);
    handleTimer(response?.termAcceptTimeline);
    setAcceptTimeSO(response?.termAcceptTimeline);
    // const dateA = new Date(response?.voLiftSubRequestDate);
    // dateA.setHours(dateA.getHours() + 5);
    // dateA.setMinutes(dateA.getMinutes() + 30);
    setVoLiftSubTimer(response?.voLiftSubRequestDate);
    setCmLiftSubExtensionTime(response?.cmLiftSubExtensionRequestTimeline);
    setDisplayAcceptRejectChngeMsg(response?.showExtensionNotification);
    setExtensionTimeLine(response?.extensionTimeline);
    setIsPrefilledCondition(response?.isExtended);
    setExtendedContractIDBCP(response?.extendedContractId);

    if (response?.enableCheckout === false) {
      setIsEditing(false);
    }
    if (response?.accessType === "Write") {
      getComments();
    }
    if (type == "yes" || response?.checkoutTime !== null) {
      handleRunningTimeOnEdit(response?.checkoutTime);
      if (response?.accessType === "Write" && response?.enableCheckout) {
        setIsEditing(true);
        if (response?.bcpEdit) {
          setIsBCPEditable(true);
        }
        // getComments();
      } else {
        setIsEditing(false);
      }
    } else {
      setTimerSecond(0);
      setTimerExpiry(false);
    }
  };
  const handleRunningTimeOnEdit = (time) => {
    const dateA = new Date(time);
    dateA.setHours(dateA.getHours() + 5);
    dateA.setMinutes(dateA.getMinutes() + 30);
    var timeA = moment(dateA);
    var timeB = moment();
    var timeC = timeB.diff(timeA, "seconds");
    const initialTime =
      timeC > 0 ? moment.utc(timeC * 1000).format("HH:mm:ss") : "00:00:00";
    setTimerSecond(initialTime);

    if (timeC >= 3600) {
      setTimerExpiry(true);
    } else {
      setTimerExpiry(false);
    }
    const intervalId = setInterval(() => {
      setTimerSecond((prevTime) => {
        const nextTime = moment
          .utc(moment.duration(prevTime).as("milliseconds") + 1000)
          .format("HH:mm:ss");
        if (moment.duration(nextTime).as("seconds") >= 3600) {
          setTimerExpiry(true);
        }
        return nextTime;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  };
  const trackExpiryTime = async () => {
    const expiryTime = await getExpiryTime();
    if (expiryTime) {
      const currentTime = new Date();
      const expiryDate = new Date(expiryTime);
      const timeDifference = expiryDate.getTime() - currentTime.getTime();
      const settedTime1 = 60 * 60 * 1000; // 1 hour before expiry
      const settedTime2 = 30 * 60 * 1000; // 30 minutes before expiry
      const settedTime3 = 15 * 60 * 1000; // 15 minutes before expiry
      if (
        timeDifference > settedTime1 &&
        timeDifference < settedTime1 + 60 * 1000
      ) {
        console.warn("Warning: Token will expire in less than 1 hour!");
        setWillTokenExpire(true);
        setTokenExpiryMessage(
          `Warning: Token will expire in 1 hour! Please save your changes.`
        );
      }
      if (
        timeDifference > settedTime2 &&
        timeDifference < settedTime2 + 60 * 1000
      ) {
        console.warn("Warning: Token will expire in less than 30 minutes!");
        setWillTokenExpire(true);
        setTokenExpiryMessage(
          `Warning: Token will expire in 30 minutes! Please save your changes.`
        );
      }
      if (
        timeDifference > settedTime3 &&
        timeDifference < settedTime3 + 60 * 1000
      ) {
        console.warn("Warning: Token will expire in less than 15 minutes!");
        setWillTokenExpire(true);
        setTokenExpiryMessage(
          `Warning: Token will expire in 15 minutes! Please save your changes.`
        );
      }
    }
  };

  const scheduleExpiryCheck = async () => {
    await trackExpiryTime();
    setInterval(trackExpiryTime, 60000);
  };

  useEffect(() => {
    getMainSectionData("no");
    getStandardTermData();
    scheduleExpiryCheck();
  }, []);
  const changeHandler = (item: any) => {
    setPrevTab(tab);
    if (item.detail !== undefined) {
      if (item.detail !== null) {
        setTab(item.detail);
        handleTime(item.detail);
      }
    }
    if (item.detail === 0) {
      getStandardTermData();
      setIsInitialRunningRecap(false);
    }
    if (item.detail === 1) {
      // BCPresponse();
      BCPNewAPIResponse();
      setIsInitialRunningRecap(false);
    }
    if (item.detail === 2) {
      getVesselDetails();
      setIsInitialRunningRecap(false);
    }
    if (item.detail === 3) {
      getRecapStatus("tabChange");
      setIsInitialRunningRecap(true);
    }
  };
  const handleTime = (tabNo: any) => {
    if (tabNo === 0 || tabNo === 1 || tabNo === 3) {
      handleTimer(acceptTimeSO);
    } else handleTimer(0);
    // Skipping Vessel Supply Details tab
    // else if (tabNo === 3) {
    //   handleTimer(acceptTimeRR);
    // } else handleTimer(0);
  };

  const getRecapStatus = async (eventType?: any) => {
    setIsMainLoader(true);
    const response = await getAPI(
      `Negotiation/GetRecapStatusAsync/${indicationRequestId}`
    );

    if (response) {
      setRunningRecapStatus(response);
      setAcceptTimeRR(response?.liftSubTimeline);
      // handleTimer(response?.liftSubTimeline);
      setExtensionTimeLineRR(response?.liftSubExtensionTimeline);
      setIsExtensionNotifyRunningRecap(
        response?.liftSubExtensionTimeline ? true : false
      );
      if (isInitialLoad) {
        setIsMainLoader(false);
      } else {
        if (response?.isCleanRecapCompleted) {
          if (eventType !== "tabChange") {
            getStandardTermData();
          } else {
            setIsMainLoader(false);
          }
        } else {
          setIsMainLoader(false);
        }
      }
    }
  };

  const extentionAccept = (type: any) => async () => {
    const payLoad = {
      indicationId: Number(indicateId),
      timeLine: type === "accept" ? extensiontimeLineRR : acceptTimeRR,
      status: type,
      comment: "",
    };

    const response = await postAPI(
      `Negotiation/LiftSubTimelineExtenstionResponse`,
      payLoad
    );
    if (response.status) {
      toast.error(`Extension of Timeline failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      toast.success(`New Extension Time For Lift Subs has ${type}ed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setIsExtensionNotifyRunningRecap(false);
      reRenderFuncRR();
    }
  };

  const liftSubExtentionAccept = (type: any) => async () => {
    const payLoad = {
      indicationId: Number(indicateId),
      timeLine:
        type === "accept"
          ? cmLiftSubExtensionTime
          : mianInfoData?.cmLiftSubRequestDate,
      status: type,
      comment: "",
    };

    const response = await postAPI(
      `Negotiation/LiftSubTimelineExtenstionResponse`,
      payLoad
    );
    if (response.status) {
      toast.error(`Extension of Timeline failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      toast.success(`New Extension Time For Lift Subs has ${type}ed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      reRenderFuncRR();
      getMainSectionData("no");
    }
  };

  const extentionAcceptSOBCP =
    (type: any, negotiationType: any) => async () => {
      const timeLineValue = ExtensionTimeLine;
      const termsTimeValue = acceptTimeSO;
      const payLoad = {
        indicationId: indicateId,
        timeLine: type === "accept" ? timeLineValue : termsTimeValue,
        status: type,
        negotiationType: negotiationType,
      };

      const response = await postAPI(
        `InitialOffer/ProcessTimelineExtensionResponse`,
        payLoad
      );
      if (response.StatusCode) {
        toast.error(`Extending TimeLine failed`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      } else {
        toast.success(`New Extension Time has been ${type}ed`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        if (negotiationType == "Standard Offer Document") {
          reRenderFunc();
        } else if (negotiationType == "Contract Document") {
          reRenderFuncBCP();
        } else {
          reRenderFuncRR();
        }
      }
      setDisplayAcceptRejectChngeMsg(false);
      getMainSectionData("no");
    };

  const openOfferExtensionModal = () => {
    setNegotiationType("Standard Offer Document");
    setTabTermsAcceptTimeline(mianInfoData?.termAcceptTimeline);
    setIsExtensionModalOpen(true);
  };
  const openOfferExtensionModalForLiftSub = () => {
    setCmTimeForExtension({
      userType: "VO",
      timeValue: mianInfoData?.voLiftSubRequestDate,
    });
    setNegotiationType("Lift Subs");
    setIsExtensionModalOpen(true);
  };
  const getNegotiationStatusFlow = (currentStage: any) => {
    if (
      currentStage === "Clean Recap - CM" ||
      currentStage === "Terminate" ||
      currentStage === "Request Completed" ||
      currentStage === "Clean Recap - VO"
    ) {
      setStageType(true);
    } else {
      setStageType(false);
    }
  };
  const onClosePopUp = () => {
    setIsExtensionModalOpen(false);
  };
  const renderVesselFunc = () => {};
  const submitDataFun = async (
    responseDate: any,
    comment: any,
    submitCheck
  ) => {
    if (submitCheck == "yes" && negotiationType !== "Lift Subs") {
      const payLoad = {
        indicationId: indicateId,
        timeLine: responseDate,
        negotiationType: "Standard Offer Document",
        comment: comment,
        status: "accept",
      };
      const response = await postAPI(
        `InitialOffer/ProcessTimelineExtensionResponse`,
        payLoad
      );
      if (response.StatusCode) {
        toast.error(`Extension of timeline failed`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      } else {
        toast.success(`New Extension Time has been changed`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        reRenderFunc();
        reRenderFuncBCP();
      }
      setDisplayAcceptRejectChngeMsg(false);
      getMainSectionData("no");
    } else if (submitCheck == "yes" && negotiationType == "Lift Subs") {
      const payLoad = {
        indicationId: Number(indicateId),
        timeLine: responseDate,
        status: "accept",
        comment: comment,
      };
      const response = await postAPI(
        `Negotiation/LiftSubTimelineExtenstionResponse`,
        payLoad
      );
      if (response.status) {
        toast.error(`Extension of timeline failed`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      } else {
        const displayValueOfDocument = "Lift Subs";
        toast.success(
          `New Extension Time For ${displayValueOfDocument} has changed`,
          {
            theme: "colored",
            icon: false,
            autoClose: 3000,
          }
        );
        reRenderFuncRR();
      }
    }
  };
  useEffect(() => {
    if (submitData.length !== 0 && BCPData.length !== 0) {
      setIsInitialLoad(false);
    }
  }, [submitData, BCPData]);
  const handleSave = () => {
    toast.success(`Data Saved Successfully`, {
      theme: "colored",
      icon: false,
      autoClose: 3000,
    });
  };
  const handleEditClick = () => {
    if (
      mianInfoData?.initiatedNegotiation &&
      mianInfoData?.showAcceptClausePopup
    ) {
      setAcceptClauseWrngModal(true);
    } else {
      setTab(0);
      setTimerAPI();
    }
  };

  const setTimerAPI = async () => {
    setDisableEditBtn(true);
    const response = await postAPI(
      `IndicationRequest/LockOrUnlockIndication?indicationId=${indicateId}&locked=true`
    );
    if (response) {
      getMainSectionData("yes");
      // getComments();
      setIsEditing(true);
      if (mianInfoData?.bcpEdit) {
        setIsBCPEditable(true);
      }
      setDisableEditBtn(false);
    } else {
      setIsEditing(false);
      setDisableEditBtn(false);
      toast.error(`Edit Negotiation failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
  };
  const getComments = async () => {
    const resp: any = await getAPI(
      `Negotiation/GetNegotiationComments?indicationId=${indicateId}`
    );
    setCommentMainData(resp);
    const newArray: any = [];
    if (resp?.length > 0) {
      resp?.forEach((element: any) => {
        element?.counterGroupByUserTypes?.forEach((group: any) => {
          group?.negotiationComments?.forEach((comments: any) => {
            newArray.push(comments);
          });
        });
      });
    }
    setCommentData(newArray);
  };
  const submitComment = async () => {
    if (isCommentText !== "") {
      setIsChatSendDisabled(true);
      const data: any = {
        indicationId: Number(indicateId),
        comments: isCommentText,
      };
      const resp = await postAPI(`Negotiation/StageNegotiationComment`, data);
      if (resp === true) {
        getComments();
        setIsCommentText("");
        setIsChatSendDisabled(false);
      }
    }
  };
  const getDataFromStandardOffer = (data) => {
    setStandardOfferFilledData(data);
  };
  const SubmitNegotiationModalFunc = () => {
    // to find null values and revert later false fields
    const yetToFillItems: any = getMandatoryTermsFilledVO(
      standardOfferFilledData
    );
    if (yetToFillItems.length > 0) {
      setUnFilledData(yetToFillItems);
      setIsUpdateModal(true);
    } else {
      const resultFlag = getAllTermsFilledVO(standardOfferFilledData,isPrefilledCondition);

      if (resultFlag) {
        setIsMandatoryNotifyModal(true);
      } else {
        setIsSubmitNegotiation(true);
      }
    }
  };
  const closeModalFunc = () => {
    setIsUpdateModal(false);
  };
  const closeMandatoryNotifyModal = () => {
    setIsMandatoryNotifyModal(false);
  };
  const reLoadApis = () => {
    if (tab === 0) {
      getStandardTermData();
    } else if (tab === 1) {
      // BCPresponse();
      BCPNewAPIResponse();

    } else if (tab === 3) {
      getRecapStatus();
    }
    getMainSectionData("no");
    getNegotiationStatusFlow(mianInfoData?.currentStage);
    // if (mianInfoData?.counterNumber == 0) {
    setTimeout(() => {
      //call the NPS feedback method
      shouldPromptFeedback();
    }, 4000);
    // }
  };

  const closeAcceptWarngModal = (type: string) => {
    if (type === "OK") {
      setAcceptClauseWrngModal(false);
      setIsEditing(true);
      if (mianInfoData?.bcpEdit) {
        setIsBCPEditable(true);
      }
      setTimerAPI();
      setTab(0);
    } else {
      setAcceptClauseWrngModal(false);
    }
  };
  // Function to check if feedback prompt should be shown
  const shouldPromptFeedback = (): any => {
    const lastTimestamp: any = localStorage.getItem(
      LAST_FEEDBACK_TIMESTAMP_KEY
    );
    // Check if the last timestamp is more than two months old
    const lastDate = new Date(lastTimestamp);
    const now = new Date();
    const twoMonthsAgo = new Date(
      now.getFullYear(),
      now.getMonth() - 2,
      now.getDate()
    );

    if (mianInfoData?.counterNumber == 0) {
      setShowFeedbackPrompt(true);
    } else {
      if (mianInfoData?.counterNumber == 1) {
        if (lastTimestamp) {
          setShowFeedbackPrompt(false);
        } else {
          setShowFeedbackPrompt(true);
        }
      } else {
        if (lastTimestamp) {
          setShowFeedbackPrompt(false);
        } else {
          lastDate < twoMonthsAgo
            ? setShowFeedbackPrompt(false)
            : setShowFeedbackPrompt(true);
        }
      }
    }
  };

  const handleCloseFeedbackPrompt = (type: string) => {
    if (type == "no") {
      localStorage.removeItem(LAST_FEEDBACK_TIMESTAMP_KEY);
      localStorage.removeItem("last_feedback_timestamp");
    }
    setShowFeedbackPrompt(false);
  };

  return (
    <div className="initialOfferDetails_main">
      <div className="initialOfferDetails_offer__breadcrumbs">
        <span> Negotiate / Initiate Offer </span>
      </div>
      {mianInfoData?.showConfidentialMessage && (
        <div className="confidential-info">
          <McIcon icon="shield-check"></McIcon> ALL NEGOTIATIONS AND EVENTUAL
          FIXTURE TO BE KEPT STRICTLY PRIVATE AND CONFIDENTIAL
        </div>
      )}
      <div className="initialOfferDetails_section">
        <div className="initialOfferDetails_section__body">
          <div className="parentdiv">
            <div className="nestedDiv">
              {/* {isExtensionNotifyRunningRecap && (
                <div className={`extensionIndication indication-extension`}>
                  <span className="icon-notification">
                    <McIcon icon="bell"></McIcon>
                  </span>
                  <label>
                    New Extension Time{" "}
                    <b>
                      {moment(extensiontimeLineRR).format("DD-MMM-YYYY HH:mm")}
                    </b>{" "}
                    For <b>Lift Subs</b> has been requested by Chartering
                    Manager, <br />
                    Do you want to{" "}
                    <span className="extensionIndication_button">
                      <button
                        className="btn btn-primary"
                        data-testid="extension-accept"
                        onClick={extentionAccept("accept")}
                      >
                        Accept
                      </button>{" "}
                      <button
                        className="btn btn-primary"
                        data-testid="extension-reject"
                        onClick={extentionAccept("reject")}
                      >
                        Reject
                      </button>{" "}
                      or
                      <button
                        className="btn btn-primary"
                        onClick={() => openOfferExtensionModal()}
                      >
                        Change
                      </button>
                    </span>
                  </label>
                </div>
              )} */}
              {!stageType &&
                mianInfoData?.accessType === "Write" &&
                mianInfoData?.cmLiftSubExtensionRequestTimeline &&
                (mianInfoData?.cmLiftedSubsOn === null ||
                  mianInfoData?.cmLiftedSubsOn === "") && (
                  <div className={`extensionIndication indication-extension`}>
                    <span className="icon-notification">
                      <McIcon icon="bell"></McIcon>
                    </span>
                    <label>
                      New Extension Time{" "}
                      <b>
                        {moment(cmLiftSubExtensionTime).format(
                          "DD-MMM-YYYY HH:mm"
                        )}
                      </b>{" "}
                      For <b>Lift Subs</b> has been requested by Chartering
                      Manager, <br />
                      Do you want to{" "}
                      <span className="extensionIndication_button">
                        <button
                          className="btn btn-primary"
                          data-testid="extension-accept"
                          onClick={liftSubExtentionAccept("accept")}
                        >
                          Accept
                        </button>{" "}
                        <button
                          className="btn btn-primary"
                          data-testid="extension-reject"
                          onClick={liftSubExtentionAccept("reject")}
                        >
                          Reject
                        </button>{" "}
                        or
                        <button
                          className="btn btn-primary"
                          onClick={() => openOfferExtensionModalForLiftSub()}
                        >
                          Change
                        </button>
                      </span>
                    </label>
                  </div>
                )}
            </div>
            <div className="nestedDiv">
              {!stageType && displayAcceptRejectChngeMsg == true && (
                <div className={`extensionIndication indication-extension`}>
                  <span className="icon-notification">
                    <McIcon icon="bell"></McIcon>
                  </span>
                  <label>
                    New Extension Time{" "}
                    <b>
                      {moment(ExtensionTimeLine).format("DD-MMM-YYYY HH:mm")}
                    </b>{" "}
                    has been requested by Chartering Manager, <br />
                    Do you want to{" "}
                    <span className="extensionIndication_button">
                      <button
                        className="btn btn-primary"
                        data-testid="extension-acceptsobcp"
                        onClick={extentionAcceptSOBCP(
                          "accept",
                          "Standard Offer Document"
                        )}
                      >
                        Accept
                      </button>{" "}
                      <button
                        className="btn btn-primary"
                        data-testid="extension-rejectsobcp"
                        onClick={extentionAcceptSOBCP(
                          "reject",
                          "Standard Offer Document"
                        )}
                      >
                        Reject
                      </button>{" "}
                      or
                      <button
                        className="btn btn-primary"
                        onClick={() => openOfferExtensionModal()}
                      >
                        Change
                      </button>
                    </span>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="row initiate-offer-tabs">
            <div className="col-md-1">
              {!stageType && (
                <div className="timer-wrapper">
                  <div className="negotiation-type">
                    <div>Main Terms</div>
                    {/* {tab === 0 ? (
                    <div>Main Terms</div>
                  ) : tab === 1 ? (
                    <div>Charter Party</div>
                  ) : tab === 2 ? (
                    <div>Vessel Supply Details</div>
                  ) : tab === 3 ? (
                    <div>Running Recap</div>
                  ) : null} */}
                  </div>
                  <CountdownCircleTimer
                    {...timerProps}
                    duration={timerInitial}
                    initialRemainingTime={remainingTime}
                    colors={["#42b0d5", "#F7B801", "#A30000", "#bcbcbc"]}
                    colorsTime={[90, 50, 20, 0]}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                  {!stageType &&
                    mianInfoData &&
                    mianInfoData?.accessType === "Write" &&
                    mianInfoData?.showExtensionRequestLink === true &&
                    mianInfoData?.extensionTimeline == null && (
                      <div
                        className="extension-div"
                        data-testid="main-term-request"
                        onClick={openExtensionModal}
                      >
                        <label>Request for Extension</label>
                      </div>
                    )}

                  {!stageType &&
                    mianInfoData &&
                    mianInfoData?.accessType === "Write" &&
                    !stageType &&
                    mianInfoData?.enableCheckout &&
                    mianInfoData?.showExtensionRequestLink === false &&
                    mianInfoData?.extensionTimeline !== null && (
                      <div className="extension-div">
                        <label>Request Sent</label>
                      </div>
                    )}
                  <ChangeOfferTimeModal
                    isModalOpen={extensionModalOpen}
                    onLoadData={extensiontimeLineRR}
                    onCloseFunc={onClosePopUp}
                    submitData={submitDataFun}
                    liftSubExtensionTime={cmTimeForExtension}
                    negotiationType={negotiationType}
                  />
                </div>
              )}
              {stageType && (
                <div className="empty-timer-section">
                  <div className="empty-timer"></div>
                </div>
              )}
            </div>
            <div className="col-md-11">
              <div className="step-indicator">
                <div className="terminate-section">
                  <span className="counter">
                    <span className="counter-number">
                      {mianInfoData?.counterNumber}
                    </span>
                    <span className="counter-text">Counter</span>
                  </span>
                </div>
                <StepIndicatorFlow
                  type={"Negotiation"}
                  indicationID={indicateId}
                  updateApiProps={isMainLoader}
                  getStatusFlow={getNegotiationStatusFlow}
                />
              </div>
            </div>
          </div>
          <div className="negotiation-mainInfo">
            <div className="row">
              <div className="col">
                <div className="info-div">
                  <div className="info-label">Vessel Name</div>
                  <div className="info-value">{mianInfoData?.vesselName}</div>
                </div>
              </div>
              <div className="col">
                <div className="info-div">
                  <div className="info-label">IMO</div>
                  <div className="info-value">{mianInfoData?.imo}</div>
                </div>
              </div>
              {/* <div className="col">
                <div className="counter-info">
                  <div className="counter-label">Counter</div>
                  <div className="counter-value">
                    {mianInfoData?.counterNumber}
                  </div>
                </div>
              </div> */}
              <div className="col">
                <div className="info-div">
                  <div className="info-label">Access Type</div>
                  <div className="info-value">{mianInfoData?.accessType}</div>
                </div>
              </div>
              {accessType === "Write" && (
                <>
                  {mianInfoData?.voLiftSubRequestDate && (
                    <div className="col lift-sub-timer-div">
                      <div className="info-div">
                        <div className="info-label">VO Lift Sub</div>
                        {mianInfoData?.voLiftedSubsOn !== null &&
                        mianInfoData?.voLiftedSubsOn !== "" ? (
                          <div className="ls-value-completed">
                            Completed{" "}
                            <span className="timerShow">
                              {moment(mianInfoData?.voLiftedSubsOn).format(
                                "DD-MMM-YYYY HH:mm"
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className="ls-value" data-testid="liftsub-time">
                            {voLiftSubRemainingTime}{" "}
                            <span className="date-display">
                              (
                              {moment(
                                mianInfoData?.voLiftSubRequestDate
                              ).format("DD-MMM-YYYY HH:mm")}
                              )
                            </span>
                          </div>
                        )}
                      </div>
                      {mianInfoData?.voLiftSubRequestDate &&
                        mianInfoData?.voLiftSubExtensionRequestTimeline &&
                        mianInfoData?.accessType === "Write" &&
                        (mianInfoData?.voLiftedSubsOn === null ||
                          mianInfoData?.voLiftedSubsOn === "") && (
                          <div className="extension-div-sent">
                            <label>Request Sent</label>
                          </div>
                        )}
                      {mianInfoData?.voLiftSubRequestDate &&
                        !mianInfoData?.voLiftSubExtensionRequestTimeline &&
                        mianInfoData?.accessType === "Write" &&
                        (mianInfoData?.voLiftedSubsOn === null ||
                          mianInfoData?.voLiftedSubsOn === "") && (
                          <div
                            className="extension-div"
                            onClick={openLiftSubExtensionModal}
                          >
                            <label>Request for Extension</label>
                          </div>
                        )}
                    </div>
                  )}
                  {mianInfoData?.initiatedNegotiation && (
                    <div className="col">
                      <div className="islocked">
                        <div className="info-label">Negotiation</div>
                        <div className="icon-div">
                          <McIcon
                            icon={
                              mianInfoData?.enableCheckout === false
                                ? "lock"
                                : "lock-unlocked"
                            }
                            size="20"
                          ></McIcon>
                        </div>
                      </div>
                    </div>
                  )}

                  {mianInfoData?.enableCheckout && !stageType && (
                    <div className="col-md-2">
                      <div className="button">
                        {!isEditing && (
                          <div>
                            <McButton
                              fit="small"
                              label="Edit Negotiation"
                              className="edit-button"
                              data-testid="edit-button"
                              onClick={handleEditClick}
                              disabled={disableEditBtn}
                            ></McButton>
                            {disableEditBtn && (
                              <div className="loader-area">
                                <McLoadingIndicator
                                  hiddenlabel
                                ></McLoadingIndicator>
                              </div>
                            )}
                          </div>
                        )}
                        {isEditing && (
                          <div>
                            <div className="action-buttons">
                              <McButton
                                fit="small"
                                label="Submit Counter/Offer"
                                className="submit-button"
                                onClick={() => SubmitNegotiationModalFunc()}
                                data-testid="SubmitNegotiationModalFunc"
                              ></McButton>
                              <McButton
                                data-testid="save-button"
                                fit="small"
                                label="Save"
                                className="save-button"
                                onClick={handleSave}
                              ></McButton>
                            </div>
                            {/* <div className="timer-value">{timerSecond}</div> */}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              {mianInfoData?.documentName !== null && (
                <div className="col-md-1">
                  <div
                    className="documentName"
                    title={mianInfoData?.documentName}
                  >
                    {mianInfoData?.documentName}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`comment-section active`}>
            <div
              data-testid="chat-circle"
              id="chat-circle"
              className="btn btn-raised"
              onClick={() => setIsComment(true)}
            >
              <div id="chat-overlay"></div>
              <McIcon icon="comment"></McIcon>
            </div>

            <div className={`chat-box ${isComment ? "show-chatbox" : ""} `}>
              <div className="chat-box-header">
                Comment Section
                <span
                  data-testid="chat-box-toggle"
                  className="chat-box-toggle"
                  onClick={() => setIsComment(false)}
                >
                  <img
                    className="close-msg-icon"
                    src={closeIcon}
                    alt="close"
                  ></img>
                  {/* <i className="material-icons">close</i> */}
                </span>
              </div>
              <div className="chat-box-body">
                <div className="chat-box-overlay"></div>
                <div className="chat-logs">
                  {commentData?.map((item) => (
                    <div className="chat-msg">
                      <div className="chat-text-div">
                        <span
                          className={`user-role  ${
                            item?.userType === "CharteringManager" ? "cm" : "vo"
                          }`}
                        >
                          {item?.userType === "CharteringManager" ? "CM" : "VO"}
                        </span>
                        <span className="user-chat">{item?.comments}</span>
                      </div>
                      <span className="details">
                        <span className="counter">
                          Counter - <span>{item?.counterNumber}</span>
                        </span>
                        <span className="time">
                          {moment(item?.createdOn).format("DD-MMM-YYYY HH:mm")}
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {isEditing && (
                <div className="chat-input">
                  <input
                    type="text"
                    onChange={(e) => setIsCommentText(e.target.value)}
                    id="chat-input"
                    value={isCommentText}
                    placeholder="Comment..."
                  />
                  <button
                    data-testid="chat-submit"
                    onClick={submitComment}
                    className={`chat-submit ${
                      isChatSendDisabled ? "disabled" : ""
                    }`}
                    disabled={isChatSendDisabled}
                    id="chat-submit"
                  >
                    <img
                      className="message-send-icon"
                      src={messageSendIcon}
                      alt="send"
                    ></img>
                    {/* <i className="material-icons">send</i> */}
                  </button>
                </div>
              )}
            </div>
          </div>
          {mianInfoData?.enableCheckout && mianInfoData?.counterNumber >= 0 && (
            <span className="time-expiry">
              <div className="expiry-information">
                <McIcon
                  icon="info-circle"
                  className="information-icon"
                ></McIcon>
                <span>
                  Accepted main terms will be confirmed upon submission of the
                  counter.
                </span>
              </div>
            </span>
          )}
          <div className="doucumentsType-section">
            {isMainLoader && (
              <div className="loader-div  ">
                <div className="width-div">
                  <div className="image-div shipLoader" id="pot">
                    <img src={laderImage} alt="" />
                  </div>
                </div>
              </div>
            )}
            <McTabBar
              data-testid="change-handler"
              variant="stretched"
              currentindex={tab}
              change={changeHandler}
            >
              <McTab
                slot="tab"
                label="Main Terms"
                data-testid="main-terms"
                icon="file"
              ></McTab>
              <div slot="panel">
                <StandardOfferVO
                  data={offerdata}
                  indicationId={indicateId}
                  submitData={submitData}
                  reRenderApiFunc={reRenderFunc}
                  accessType={accessType}
                  stageType={stageType}
                  isEditing={isEditing}
                  mainInfoData={mianInfoData}
                  dataBack={getDataFromStandardOffer}
                  standardDataLoaded={standardOfferAPILoad}
                  isPrefilledCondition={isPrefilledCondition}
                />
              </div>
              <McTab
                slot="tab"
                label="Charter Party"
                data-testid="charter-party"
                className={`${!mianInfoData?.attachedBcp ? "disbaleTab" : ""}`}
                icon="file"
              ></McTab>
              <div slot="panel">
                <IndexHomePage
                  indicationRequestId={indicationRequestId}
                  reRenderApiFunc={reRenderFuncBCP}
                  BCPData={BCPData}
                  userType={"VesselOwner"}
                  accessType={accessType}
                  stageType={stageType}
                  isEditing={isBCPEditable && isEditing}
                  mainInfoData={mianInfoData}
                  extendedContractIDBCP={extendedContractIDBCP}
                  isPrefilledCondition={isPrefilledCondition}

                />
              </div>
              <McTab
                slot="tab"
                label="Vessel Supply Details"
                className="disbaleTab"
                icon="file"
                data-testid="vessel-supply-details"
              ></McTab>
              <div slot="panel">
                <VesselSupplyDetails
                  accessType={accessType}
                  maindata={vesselDetails}
                  stageType={stageType}
                  renderFunc={renderVesselFunc}
                  IndicationId={indicateId}
                />
              </div>
              <McTab
                slot="tab"
                label="Running Recap"
                data-testid="running-recap"
                icon="file"
              ></McTab>
              <div slot="panel">
                <IssueRecapHomePage
                  indicationRequestId={indicationRequestId}
                  reRenderApiFunc={reRenderFuncRR}
                  data={runningRecapData}
                  recapStatus={runningRecapStatus}
                  accessType={accessType}
                  isEditing={isEditing}
                  isInitialRunningRecap={isInitialRunningRecap}
                  isPrefilledCondition={isPrefilledCondition}
                  extendedContractIDBCP={extendedContractIDBCP}
                />
              </div>
            </McTabBar>
          </div>
        </div>
        <SubmitNegotiationModal
          indicationId={indicateId}
          onCloseFunc={() => setIsSubmitNegotiation(false)}
          isModalOpen={isSubmitNegotiation}
          reLoadApis={reLoadApis}
          userDetail={"VO"}
          mainInfoData={mianInfoData}
          AttachBCPFromApi={mianInfoData?.attachedBcp}
        />
        <ExtensionModal
          isModalOpen={isExtensionModal}
          indicationId={indicateId}
          termsAcceptTimeline={tabTermsAcceptTimeline}
          negotiationType={negotiationType}
          onCloseFunc={closeExtensionModal}
          reRenderApiFunc={getReRenderFunc}
          liftSubTimeForExtension={cmTimeForExtension}
        />
        {willTokenExpire && (
          <TokenExpiryPop
            onModalOpen={willTokenExpire}
            onModalClose={() => setWillTokenExpire(false)}
            modalTxt={tokenExpiryMessage}
          />
        )}
        {acceptClauseWrngModal && (
          <AcceptClauseWarningModal
            isModalOpen={acceptClauseWrngModal}
            indicationId={indicateId}
            onCanceled={closeAcceptWarngModal}
          ></AcceptClauseWarningModal>
        )}
        {isUpdateModal && (
          <UpdateUnfilledFieldsModal
            isModalOpen={isUpdateModal}
            onCloseFunc={closeModalFunc}
            indicationId={indicateId}
            termsData={unFilledData}
          />
        )}
        {isMandatoryNotifyModal && (
          <MandatoryNotifyModal
            isModalOpen={isMandatoryNotifyModal}
            onCloseFunc={closeMandatoryNotifyModal}
          />
        )}
        <FeedBackPrompt
          showModal={showFeedbackPrompt}
          onClose={handleCloseFeedbackPrompt}
        />
      </div>
    </div>
  );
};
export default InitiateOfferDetailsVO;