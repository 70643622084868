// React
import { useState, useRef, useEffect } from "react";
import { McCheckbox, McIcon } from "@maersk-global/mds-react-wrapper";
import { postAPI } from "../../../services/CepApiServices";
import HistoryPage from "./HistoryPage";
import "./IndexHomePage.scss";
import { toast } from "react-toastify";
//Config
import { getUID } from "../../../utils/token";
import { Collapse } from "react-bootstrap";
import addLogIcon from "../../../assets/image-gallery.png";
import closeLogIcon from "../../../assets/minimize.png";
interface IPROPS {
  indicationId?: any;
  cluaseData?: any;
  onAPiLoadNew?: any;
  titleId?: any;
  isTitle?: any;
  userRole?: any;
  documentId?: any;
  fromIssueRecap?: any;
  checkNegotiationStatus?: any;
  isWriteAccess?: any;
  isEditing?: any;
  handleRevertCheck?: any;
  mainInfoData?: any;
  index?: any;
  isPrefilledCondition?:any;
  extendedContractIDBCP?:any;
}

const ClausePage = ({
  indicationId,
  cluaseData,
  onAPiLoadNew,
  titleId,
  isTitle,
  userRole,
  documentId,
  fromIssueRecap,
  checkNegotiationStatus,
  isWriteAccess,
  isEditing,
  handleRevertCheck,
  mainInfoData,
  index,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const inputRef: any = useRef([]);
  const inputRef2 = useRef([]);
  const [textareaDiv, setTextareaDiv] = useState(isEditing);
  const [value, setValue] = useState("");
  const [acceptBtn, setAcceptBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(true);
  const [historyBtn, setHistoryBtn] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [paraId, setparaId] = useState<any>([]);
  const [isChangesSubmitting, setIsChangesSubmitting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isLogDivOpen, setIsLogDivOpen] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const UID = getUID();
  useEffect(() => {
    if (isEditing) {
      if (!mainInfoData?.attachedBcp) {
        setTextareaDiv(true);
      } else if (
        cluaseData?.allowParaEdit === true &&
        (cluaseData?.status !== "Accept" && cluaseData?.status !== "NegotiatedAndAccepted")
      ) {
        setTextareaDiv(true);
      } else {
        setTextareaDiv(false);
      }
    } else {
      setTextareaDiv(false);
    }
  }, [isEditing]);

  useEffect(() => {
    fetchHistoryBtnCondition();
    fetchEditBtnCondition();
  });

  const fetchHistoryBtnCondition = () => {
    if (cluaseData.status === null || cluaseData.status === "New")
      setHistoryBtn(false);
    else if (
      cluaseData.status === "NegotiatedAndAccepted" ||
      cluaseData.status === "Awaiting"
    )
      setHistoryBtn(true);
  };

  const fetchEditBtnCondition = () => {
    if (
      (userRole == "VesselOwner" && cluaseData.status == "New") ||
      (userRole == "CharteringManager" && cluaseData.status == "New") ||
      (userRole == "OwnerRepresentative" && cluaseData.status == "New")
    ) {
      setEditBtn(true);
    }
    if (
      (userRole == "VesselOwner" && cluaseData.status == "Accept") ||
      (userRole == "CharteringManager" &&
        cluaseData.status == "Accept") ||
      (userRole == "OwnerRepresentative" && cluaseData.status == "Accept")
    ) {
      setEditBtn(false);
    }
  };

  const onHistory = () => {
    setHistoryModalOpen(true);
    setparaId(cluaseData?.paragraphId);
  };

  const closehistory = () => {
    setHistoryModalOpen(false);
  };

  const returnJoinData = (val: any) => {
    const joineddata: any = [];
    // const highlightClasses = ['highlightBlack', 'highlightRed', 'highlightGreen'];
    // className={`${highlightClasses[element.highlight]}`}
    if (Array.isArray(val)) {
      val?.forEach((element: any) => {
        // if (!fromIssueRecap && element?.operation == 0) {
        if (element?.displayFormat === 1) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
          } else {
            joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
          }
        }
        if (element?.displayFormat === 2) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            joineddata.push(newArr);
          } else {
            joineddata.push(element?.text);
          }
        }
        if (element?.displayFormat === 0) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</b>);
          } else {
            joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
          }
        }
        if (element?.displayFormat === 3) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
          } else {
            joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
          }
        }
      });
    }

    return joineddata;
  };
  // const returnJoinDataAcceptedClause = (val: any) => {
  //   const joineddata: any = [];
  //   // const highlightClasses = ['highlightBlack', 'highlightRed', 'highlightGreen'];
  //   // className={`${highlightClasses[element.highlight]}`}
  //   if (Array.isArray(val)) {
  //     val?.forEach((element: any) => {
  //       // if (!fromIssueRecap && element?.operation == 0) {
  //       if (element?.displayFormat === 1) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           // joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
  //           joineddata.push(newArr);
  //         } else {
  //           // joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
  //           joineddata.push(element?.text);
  //         }
  //       }
  //       if (element?.displayFormat === 2) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           joineddata.push(newArr);
  //         } else {
  //           joineddata.push(element?.text);
  //         }
  //       }
  //       if (element?.displayFormat === 0) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           // joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</b>);
  //           joineddata.push(newArr);
  //         } else {
  //           // joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
  //           joineddata.push(element?.text);
  //         }
  //       }
  //       if (element?.displayFormat === 3) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           // joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
  //           joineddata.push(newArr);
  //         } else {
  //           // joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
  //           joineddata.push(element?.text);
  //         }
  //       }
  //     });
  //   }

  //   return joineddata;
  // };
  const returnJoinDataAcceptedClause = (val: any) => {
    const joineddata: any = [];
    // const highlightClasses = ['highlightBlack', 'highlightRed', 'highlightGreen'];
    // className={`${highlightClasses[element.highlight]}`}
    if (Array.isArray(val)) {
      val?.forEach((element: any) => {
        // if (!fromIssueRecap && element?.operation == 0) {
        if (element?.displayFormat === 1) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            // joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
            joineddata.push(newArr);
          } else {
            // joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
            joineddata.push(element?.text);
          }
        }
        if (element?.displayFormat === 2) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            joineddata.push(newArr);
          } else {
            joineddata.push(element?.text);
          }
        }
        if (element?.displayFormat === 0) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            // joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</b>);
            joineddata.push(newArr);
          } else {
            // joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
            joineddata.push(element?.text);
          }
        }
        if (element?.displayFormat === 3) {
          if (element?.text?.indexOf("<br>") > -1) {
            let newArr: any = [];
            newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
            // joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
            joineddata.push(newArr);
          } else {
            // joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
            joineddata.push(element?.text);
          }
        }
      });
    }

    return joineddata;
  };
  // const returnPrefilledData = (val: any) => {
  //   const joineddata: any = [];
  //   // const highlightClasses = ['highlightBlack', 'highlightRed', 'highlightGreen'];
  //   // className={`${highlightClasses[element.highlight]}`}
  //   if (Array.isArray(val)) {
  //     val?.forEach((element: any) => {
  //       // if (!fromIssueRecap && element?.operation == 0) {
  //       if (element?.displayFormat === 1) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           // joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
  //           joineddata.push([]);
  //         } else {
  //           // joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
  //           joineddata.push([]);
  //         }
  //       }
  //       if (element?.displayFormat === 2) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           joineddata.push(newArr);
  //         } else {
  //           joineddata.push(element?.text);
  //         }
  //       }
  //       if (element?.displayFormat === 0) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           // joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</b>);
  //           joineddata.push(newArr);
  //         } else {
  //           // joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
  //           joineddata.push(element?.text);
  //         }
  //       }
  //       if (element?.displayFormat === 3) {
  //         if (element?.text?.indexOf("<br>") > -1) {
  //           let newArr: any = [];
  //           newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
  //           // joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
  //           joineddata.push(newArr);
  //         } else {
  //           // joineddata.push(<b className={`${element.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
  //           joineddata.push(element?.text);
  //         }
  //       }
  //     });
  //   }

  //   return joineddata;
  // };
  const getContent = (val: any) => {
    return (
      <>
        <span>
          {val?.map((data: any) => {
            const lineNumber: any = String(data?.lineNumber);
            return <span>{data.originalContent}</span>;
          })}
        </span>
      </>
    );
  };
  const displayContent = (val: any) => {
    return (
      <>
        <table className="clauseBaseContent">
          <tbody>
            {val?.map((data: any) => {
              const lineNumber: any = String(data?.lineNumber);
              return (
                <tr>
                  <td className="dataValue">
                    {/* {data?.changeList?.length == 0 || data?.changeList === null
                      ? data.originalContent
                      : returnJoinData(data?.changeList)} */}
                      {returnJoinData(data?.changeList)}
                  </td>
                  <td className="lineNumber non-selectable">
                    {lineNumber?.indexOf(".") !== -1 ? "" : data.lineNumber}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  const displayContentAcceptedClause = (val: any) => {
    // Convert object to array
    let result = Object.keys(val).map((key) => [key, val[key]]);
    return (
      <>
        <table className="clauseBaseContent">
          <tbody>
            {result[0]?.map((data: any) => {
              return (
                <tr>
                  <td className="dataValue">
                      {returnJoinDataAcceptedClause(data?.changeList)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  const returnTextData = (val: any) => {
    const joineddata: any = [];
    val?.forEach((element: any) => {
      if (element?.displayFormat !== 1 && element?.displayFormat !== 3) {
        if (element?.text?.indexOf("<br>") > -1) {
          joineddata.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
        } else {
          joineddata.push(element?.text);
        }
      }
    });
    return joineddata.join("").replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' ');
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, pastedText);
  };
  const handlingRevert = async (event: any) => {
    if (mainInfoData?.attachedBcp) {
      handleRevertCheck(event, cluaseData);
      await postAPI(
        `Negotiation/StageBCPRevertNextCounter?indicationId=${indicationId}&paraId=${cluaseData?.paragraphId}&isChecked=${event}`
      );
    }
  };
  const handleBlur = async (canUpt: any, index:number) => {
    const newChanges = [{
      indicationRequestId: Number(id),
      paraId: cluaseData?.paragraphId,
      lineNumber: Number(canUpt?.current[index].id),
      modifiedContent: canUpt?.current[index].innerText.replaceAll("\r\n", "<br>"),
      // createdBy: UID,
      userType: userRole,
      documentId: cluaseData?.documentId,
      status: "Awaiting",
      changeList :
        cluaseData.baseCharterPartyMainClauseParaGraphsContents[index].changeList.length > 0
          ? cluaseData.baseCharterPartyMainClauseParaGraphsContents[index].changeList
          : [],
      // originalContent: cluaseData.baseCharterPartyMainClauseParaGraphsContents[index].originalContent,
    }];
    // const newChanges = canUpt?.current?.map((item: any, index: any) => ({
    //   indicationRequestId: Number(id),
    //   paraId: cluaseData?.paragraphId,
    //   lineNumber: Number(item.id),
    //   modifiedContent: item.innerText.replaceAll("\r\n", "<br>"),
    //   // createdBy: UID,
    //   userType: userRole,
    //   documentId: cluaseData?.documentId,
    //   status: "Awaiting",
    //   changeList :
    //     cluaseData.baseCharterPartyMainClauseParaGraphsContents[index].changeList.length > 0
    //       ? cluaseData.baseCharterPartyMainClauseParaGraphsContents[index].changeList
    //       : [],
    //   // originalContent: cluaseData.baseCharterPartyMainClauseParaGraphsContents[index].originalContent,
    // }));
    await postAPI(
      `Negotiation/StageBaseCharterPartyClausesAsync`,
      newChanges
    );
  };
  return (
    <>
      {
      // cluaseData?.baseCharterPartyMainClauseParaGraphsContents[0]?.originalContent !== "" &&
        cluaseData?.documentId !== null && (
          <>
            <div className="cluase-heading">
              <div className="icons-div">
                {
                  // !textareaDiv &&
                  ((historyBtn &&
                    isWriteAccess &&
                    !fromIssueRecap &&
                    checkNegotiationStatus) ||
                    fromIssueRecap === true) && (
                    <button
                      data-testid="on-history"
                      className={`bcp-clause-btn ${
                        !textareaDiv ? "" : "upper"
                      } ${
                        cluaseData?.status == "Accept"
                          ? "accept-position"
                          : ""
                      }`}
                      onClick={() => onHistory()}
                    >
                      <McIcon
                        style={{ margin: "0px 0px" }}
                        icon="arrow-anti-clockwise-clock"
                        color="#fff"
                      ></McIcon>
                    </button>
                  )
                }
                {textareaDiv && (
                  <img
                    src={!isLogDivOpen ? addLogIcon : closeLogIcon}
                    className={`${
                      !isLogDivOpen ? " add-log-icon" : "close-log-icon"
                    }`}
                    alt="pencil-icon"
                    onClick={() => setIsLogDivOpen(!isLogDivOpen)}
                  ></img>
                )}
              </div>
            </div>

            {historyModalOpen && (
              <div className="history-page-container">
                <HistoryPage
                  isModalOpen={historyModalOpen}
                  paragraphId={paraId}
                  indicationRequestId={id}
                  onClose={closehistory}
                  isPrefilledCondition={isPrefilledCondition}
                  extendedContractIDBCP={extendedContractIDBCP}
                />
              </div>
            )}
            {isTitle && index == 0 && (
              <div
                className={`subHeading ${
                  !isCollapse ? "collapseCss" : "open-box"
                } 
                ${
                  !textareaDiv && mainInfoData?.attachedBcp
                    ? "upper"
                    : "non-collapse-edit"
                } 
                ${
                  cluaseData?.status == "Accept" ? "accept-position" : ""
                }`}
              >
                {isTitle}
              </div>
            )}
            <div className="clause-body">
              {!textareaDiv &&
                mainInfoData?.attachedBcp &&
                mainInfoData?.bcpEdit &&
                cluaseData?.status !== "Accept" && (
                  <div className="label-div above-clause-body">
                    <span className="checkBox">
                      <McCheckbox
                        fit="small"
                        label="Revert Next Counter"
                        value={cluaseData?.checked ? "true" : "false"}
                        checked={cluaseData?.checked}
                        key={paraId}
                        disabled={true}
                        // change={(e:any) => {handlingRevert(e.target.checked);}}
                      ></McCheckbox>
                    </span>
                  </div>
                )}
              {cluaseData?.status === "Accept" && (
                <div className="collapsable-div">
                  {!isCollapse && (
                    <div className="headerDisplay">
                      <span className="text">
                        {/* {getContent(cluaseData?.baseCharterPartyMainClauseParaGraphsContents)} */}
                        {displayContentAcceptedClause(cluaseData?.baseCharterPartyMainClauseParaGraphsContents)}
                      </span>
                      <span
                        data-testid="isCollapse-false"
                        className="icon-to"
                        onClick={() => setIsCollapse(true)}
                      >
                        <McIcon icon="chevron-down" color="#42b0d5"></McIcon>
                      </span>
                    </div>
                  )}
                  {isCollapse && (
                    <div className="contentDisplay">
                      <div
                        className={`contentbody${
                          isEditing ? " main-copy" : ""
                        }`}
                      >
                        <pre>{displayContent(cluaseData?.baseCharterPartyMainClauseParaGraphsContents)}</pre>
                        <span
                          data-testid="isCollapse-false"
                          className="icon-to"
                          onClick={() => setIsCollapse(false)}
                        >
                          <McIcon icon="chevron-up" color="#42b0d5"></McIcon>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {cluaseData?.status !== "Accept" && (
                // <div className={`contentbody${isEditing ? " main-copy" : ""}`}>
                <>
                  {/* {textareaDiv  && (
                    <img
                      src={!isLogDivOpen ? addLogIcon : closeLogIcon}
                      className={`${!isLogDivOpen ? " add-log-icon" : "close-log-icon"}`}
                      alt="pencil-icon"
                      onClick={() => setIsLogDivOpen(!isLogDivOpen)}
                    ></img>
                  )} */}
                  <div className="contentbody main-copy">
                    <pre>{displayContent(cluaseData?.baseCharterPartyMainClauseParaGraphsContents)}</pre>
                  </div>
                  {/* {textareaDiv &&
                    isLogDivOpen &&
                    cluaseData?.paragraphHistory.length > 0 && (
                      <div className="contentbody log-copy">
                        <pre>
                          {displayContent(cluaseData?.paragraphHistory)}
                        </pre>
                      </div>
                    )} */}
                </>
              )}

              <div className={`editor-div ${isLogDivOpen ? 'show-editor' : ''}`}>
                {/* If terms are accepted, if allowParaEdit: true = dont show editor */}
                {textareaDiv && (
                  <>
                    <h6>Editor</h6>
                    {mainInfoData?.attachedBcp && mainInfoData?.bcpEdit && (
                      <div className="label-div">
                        <span className="checkBox">
                          <McCheckbox
                            data-testid="handling-revert"
                            fit="small"
                            label="Revert Next Counter"
                            value={cluaseData?.checked ? "true" : "false"}
                            checked={cluaseData?.checked}
                            key={paraId}
                            change={(e: any) => {
                              handlingRevert(e.target.checked);
                            }}
                          ></McCheckbox>
                        </span>
                      </div>
                    )}

                    <div
                      className={`editorBody ${
                        cluaseData?.checked ? "checked-content" : ""
                      }`}
                    >
                      {cluaseData?.baseCharterPartyMainClauseParaGraphsContents.map(
                        (item: any, i: number) => (
                          <>
                            <span className="pre-editorspan">
                              <pre
                                data-testid="handle-blur"
                                className="divtext"
                                ref={(el) => (inputRef.current[i] = el)}
                                id={item.lineNumber}
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(el) => {
                                  handleBlur(inputRef, i);
                                }}
                                onPaste={handlePaste}
                              >
                                {/* {item?.changeList?.length == 0 || item?.changeList === null
                                  ? item.originalContent
                                  : returnTextData(item.changeList)} */}
                                  {returnTextData(item.changeList)}
                              </pre>
                              <span className="line-num non-selectable">
                                {item?.lineNumber}
                              </span>
                            </span>
                          </>
                        )
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      <></>
    </>
  );
};
export default ClausePage;
