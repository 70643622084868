import "./FeedBackPrompt.scss";
import React, { useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { postNPSFeedback } from "../../services/FeedbackService";
// import { useAppSelector } from "../../store/store";
import { getUserRole, getUserName } from "../../utils/token";
// import { showErrorToast, showSuccessToast } from "../../utils/toastUtil";
import { toast } from "react-toastify";

const FeedbackPrompt: React.FC<{ showModal: boolean; onClose: any }> = ({
  showModal,
  onClose,
}) => {
  // State for feedback form
  const [rating, setRating] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState(true);

  // Function to handle rating selection
  const handleRatingSelect = (selectedRating: number) => {
    setRating(selectedRating);
    setSubmitDisabled(false);
  };

  // Function to handle feedback change
  const handleFeedbackChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFeedback(event.target.value);
  };

  // Function to submit feedback
  const handleSubmit = async () => {
    const userDetails = await getUserName();
    try {
      // Call the API to post NPS feedback
      const response = await postNPSFeedback({
        rating: rating || 0,
        comment: feedback,
        userEmail: userDetails.email,
        userName: userDetails.userName,
      });

      if (response) {
        toast.success("Thanks, Feedback submitted successfully!", {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        setRating(null);
        setFeedback("");
        onClose("yes");
      } else {
        toast.error("Failed to submit feedback. Please try again later.", {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        setRating(null);
        setFeedback("");
        onClose("yes");
      }
    } catch (error) {
      toast.error("Failed to submit feedback. Please try again later.", {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setRating(null);
      setFeedback("");
      onClose("yes");
    }
  };

  return (
    <Modal
      className="feedback-modal"
      show={showModal}
      onClose={onClose}
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Feedback</h5>
          <button
            type="button"
            className="btn-close"
            data-testid="close-feedback"
            onClick={() => onClose("no")}
          ></button>
        </div>
        <div className="modal-body">
          <div className="form-group1">
            <label className="feedback-ques1-text">
              <span style={{ fontWeight: "normal" }}>1. </span>
              How do you like CEP? Please give us a rating *
            </label>
            <div className="rating-buttons">
              <div className="rating-btn-start-txt">Extremely Unlikely</div>
              {[1, 2, 3, 4, 5].map((num) => (
                <button
                  key={num}
                  type="button"
                  className={`btn btn-outline-primary rating-button ${
                    rating === num ? "active" : ""
                  }`}
                  onClick={() => handleRatingSelect(num)}
                >
                  {num}
                </button>
              ))}
              <div className="rating-btn-end-txt">Extremely Likely</div>
            </div>
          </div>
          <div className="form-group2">
            <label className="feedback-ques2-text">
              <span style={{ fontWeight: "normal" }}>2. </span>Any other
              feedback?
            </label>
            <textarea
              className="form-control"
              id="feedback"
              rows={5}
              value={feedback}
              onChange={handleFeedbackChange}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submitDisabled}
            data-testid="submit-feedback"
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackPrompt;
